import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SiteMetadata from "../components/SiteMetadata"
import Layout from "../layouts/Layout"
import Button from "../components/Button"


const Compatibility = ({ data, location }) => {
  // const title = data.main.title
  // const osArray = data.main.os
  const title = data.matrixTitle.nodes[0].frontmatter.title
  const buttonArray = data.matrixTitle.nodes[0].frontmatter.array
  const imageArray = data.matrixImage.edges
  return (
    <Layout data={data.meta} location={location}>
      <SiteMetadata title="互換性" description="ChonkerKeys 互換性" />
      <div className="container py-32 mx-auto text-center">
        <h1 className="text-5xl lg:text-7xl">{title}</h1>
        <div className="my-8">
          {
            buttonArray && buttonArray.map((os) => <Link key={`text-${os}`} to={`#${os}`}><Button padding="px-8 mx-2 mb-2">{os}</Button></Link>
            )
          }
        </div>
        <div className="my-8">
          {
            imageArray && imageArray.map(os => {
              const osImage = getImage(os.node)
              return (<div key={`image-${os.node.name}`} className="py-24 border border-black rounded-3xl" id={os.node.name}><GatsbyImage imgStyle={{ border: "1px solid", borderRadius: "1.5rem" }} image={osImage} alt={`Chonkerkeys compactibility ${os.node.name}`} /></div>)
            })
          }
        </div>
      </div>
    </Layout>
  )
}

export default Compatibility

export const query = graphql`
  query CompatibilityJaQuery {
    meta: site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    # main: contentfulPageHero(title: {eq: "Compatibility > Main"}) {
    #   title: heroText
    #   os: iconsWithText {
    #     text
    #     image {
    #       media {
    #         localFile {
    #           childImageSharp {
    #             gatsbyImageData(
    #               width: 800
    #               layout: CONSTRAINED
    #               placeholder: BLURRED
    #               formats: [AUTO, WEBP]
    #             )
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
    matrixTitle: allMarkdownRemark(filter: {frontmatter: {slug: {eq: "compatibility-ja"}}}) {
      nodes {
        frontmatter {
          slug
          title
          language
          array
        }
      }
    }
    matrixImage: allFile(
      filter: {relativePath: {regex: "/ja-JP/compatibility/"}}
      sort: {fields: name, order: DESC}
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`